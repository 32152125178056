// import firebase from 'firebase/app'
import { httpClient } from "../../service";
import { apiUrl, apiKey } from "../../constants/config";
import { checkPlatform } from "../../utils";
// import 'firebase/auth'

import {
  setCurrentUser,
  getCurrentUser,
  getTokens,
  setTokens,
} from "../../utils";

export default {
  state: {
    paymentData: null,
    paymentError: null,
    paymentProcessing: false,
    paymentSuccess: null,
  },
  getters: {
    paymentData: (state) => state.paymentData,
    paymentProcessing: (state) => state.paymentProcessing,
    paymentError: (state) => state.paymentError,
    paymentSuccess: (state) => state.paymentSuccess,
  },
  mutations: {
    setpaymentData(state, payload) {
      state.paymentData = payload;
      state.paymentProcessing = false;
      state.paymentError = null;
    },
    setMyClass(state, payload) {
      state.myClass = payload;
      state.paymentProcessing = false;
      state.paymentError = null;
    },
    setpaymentProcessing(state, payload) {
      state.paymentProcessing = payload;
      state.paymentError = null;
    },
    setpaymentError(state, payload) {
      state.paymentError = payload;
      state.paymentProcessing = false;
    },
    setRegisterpaymentSuccess(state) {
      state.paymentError = null;
      state.paymentProcessing = false;
      state.paymentSuccess = true;
    },
    clearpaymentError(state) {
      state.paymentError = null;
    },
  },
  actions: {
    requestPayment({ commit, state, dispatch }, payload) {
      httpClient
        .post(
          `/v1/payment/paymentReq`,
          {
            enrollment: payload.enrollment,
            name: payload.name,
            amount: payload.amount,
          }
        )
        .then((response) => {
          const paymentData = response.data.data;
          commit("setpaymentData", paymentData);
          dispatch("callPaymentGateway", paymentData);
        });
    },
    callPaymentGateway({ commit, state, dispatch }, payload) {
      console.log(payload);
      const tokens = getTokens();
      //   goPay(payload);
      if (document.getElementById("nice_pay_goodday")) {
        document.getElementById("nice_pay_goodday").remove();
      }
      const payForm = document.createElement("form");
      payForm.method = "post";
      payForm.id = "nice_pay_goodday";
      payForm.action = `${apiUrl}/v1/payment/authReq`;
      payForm["accept-charset"] = "euc-kr";
      for (const property in payload) {
        const element = document.createElement("input");
        element.name = property;
        element.value = payload[property];
        element.type = "hidden";
        payForm.appendChild(element);
      }
      const element = document.createElement("input");
      element.name = "accessToken";
      element.value = tokens;
      element.type = "hidden";
      payForm.appendChild(element);
      document.body.appendChild(payForm);
      if (checkPlatform(window.navigator.userAgent) == "mobile") {
        payForm.action = "https://web.nicepay.co.kr/v3/v3Payment.jsp";
        payForm.acceptCharset = "euc-kr";
        payForm.submit();
      } else {
        goPay(payForm);
      }
    },
    requestCancelPayment({ commit, state, dispatch }, payload) {
      httpClient
        .post(
          `/v1/payment/cancelReq`,
          {
            msg: payload.msg,
          }
        )
        .then((response) => {
          const paymentData = response.data.data;
          commit("setpaymentData", null);
          setTimeout(() => {
            dispatch("getCurrentEnrollment");
          }, 2000);
        });
    },
  },

  
};
