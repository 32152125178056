import { UserRole } from "../utils/auth.roles";

export const defaultMenuType = 'menu-default' // 'menu-default', 'menu-sub-hidden', 'menu-hidden';
export const adminRoot = '/';
export const loginPage = '/auth/login';
export const searchPath = `${adminRoot}/#`;
export const apiUrl = 'https://api-auth.gooddayclass.com';
export const apiAuthUrl = 'https://api-auth.gooddayclass.com';
export const apiClassUrl = 'https://api-class.gooddayclass.com';
// dev server
// export const apiUrl = 'http://54.180.206.91:4000';
// export const apiAuthUrl = 'http://54.180.206.91:4000';
// export const apiClassUrl = 'http://54.180.206.91:3000';
export const apiKey = 'GCMUDiuY5a7WvyUNt9n3QztToSHzK7Uj';


export const subHiddenBreakpoint = 1440
export const menuHiddenBreakpoint = 768

export const defaultLocale = 'en'
export const defaultDirection = 'ltr'
export const localeOptions = [
  { id: 'ko', name: '한국어', direction: 'ltr' },
  { id: 'en', name: 'English LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English RTL', direction: 'rtl' }
]

export const firebaseConfig = {
  apiKey: "AIzaSyDe94G7L-3soXVSpVbsYlB5DfYH2L91aTU",
  authDomain: "signyho.com",
  databaseURL: "https://www.notion.so/62405457a94447d2b5e0810d6731431e",
  projectId: "LinkSign",
  storageBucket: "signyho.com",
  messagingSenderId: "557576321564",
  appId: "1:557576321564:web:bc2ce73477aff5c2197dd9"
};



export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Admin
}

export const isAuthGuardActive = true;
export const themeRadiusStorageKey = 'theme_radius'
export const themeSelectedColorStorageKey = 'theme_selected_color'
export const defaultColor = 'light.blueolympic'
export const colors = ['bluenavy', 'blueyale', 'blueolympic', 'greenmoss', 'greenlime', 'purplemonster', 'orangecarrot', 'redruby', 'yellowgranola', 'greysteel']

export const disablePaymentConfig = false