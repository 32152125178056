<template>
  <app-layout>
    <router-view />
  </app-layout>
</template>


<script>
import AppLayout from "../../layouts/AppLayout";
export default {
  name: "mainApp",
  components: {
    "app-layout": AppLayout,
  },
  beforeCreate() {
    this.$store.commit('setScreen', window.innerWidth);
  },
  mounted() {
    window.addEventListener('resize', () => { 
      this.$store.commit('setScreen', window.innerWidth); 
    })
  }
};
</script>
