<template>
    <div id="container">
        <div class="signin-popup-mainwrap mx-auto">
        <!-- <div class="signin-popup-headerwrap m-0 p-0 position-relative">
            <img src="../assets/Images/SignupPopBanner.svg"/>
        </div> -->
        <div id="banner">

        </div>
        <div class="signin-popup-bodywrap m-0 p-1">
            <div class="text-center d-flex justify-content-center">
                <img src="../assets/Images/live_teaching.svg" alt="">
                <img class="image mx-5" src="../assets/Images/banner.svg" width="250px" height="250px" alt="">
                <img src="../assets/Images/creative_edu.svg" class="image" alt="">
            </div>
            <div class="row w-75 w-xs-100 mx-auto py-5">
                <slot></slot>
            </div>
            <Footer/>
        </div>
    </div>
    </div>
</template>

<script>
import Footer from '../components/Footer'
export default {
    name: 'PageLayout',
    components: {
        Footer
    }    
}
</script>

<style scoped lang="scss">
#banner {
    padding: 8em 0;
    background-color: #FFE63A;
}
.image {
    margin-top: -125px;
}
.signin-popup-mainwrap{
    padding: 0px;
    margin: 0px;
}
.signin-popup-bodywrap{
    background: black;
}
.signin-popup-headerwrap{
    display: flex;
    justify-content: center;
    height: 30%;
}
.signin-popup-headerwrap img{
    width: 100%;
}
.signin-popup-bodywrap{
    height: 70%;
}
.close-icon {
    font-size:14px;
    top: 0.5em;
    right: 0.5em;
}
.cursor_pointer {
    cursor: pointer;
}

@media (max-width: 575.98px) { 
    .signin-popup-mainwrap {
        width: 100%;
    }
 }

@media (min-width: 767.98px) { 
    .signin-popup-mainwrap {
        width: 100%;
    }
 }

@media (min-width: 991.98px) { 
    .signin-popup-mainwrap {
        width: 100%;
    }
 }

@media (min-width: 1199.98px) { 
    .signin-popup-mainwrap {
        width: 100%;
    }
 }

@media (max-width: 1399.98px) { 
    .signin-popup-mainwrap {
        max-width: 100%;
    }
 }
</style>