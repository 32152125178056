import Vue from "vue";
import VueRouter from "vue-router";
import AuthGuard from "./utils/auth.guard";
import { adminRoot } from "./constants/config";
import { UserRole } from "./utils/auth.roles";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import(/* webpackChunkName: "user" */ "./views/home"),
    children: [
      {
        path: "",
        component: () => import(/* webpackChunkName: "user" */ "./NewApp"),
      },
      {
        path: "/subscription",
        name: "subscription",
        component: () => import("./components/SigninPopup"),
        meta: {
          loginRequired: true,
        },
      },
      {
        path: "/payment_status",
        name: "payment_status",
        component: () => import("./components/AllPass"),
        meta: {
          loginRequired: true,
        },
      },
      {
        path: "/class_details",
        name: "class_details",
        component: () => import("./components/ClassDetails"),
        meta: {
          loginRequired: true,
        },
      },
      {
        path: "/cancel_confirmation",
        name: "cancel_confirmation",
        component: () => import("./components/CancelConformation"),
        meta: {
          loginRequired: true,
        },
      },
    ],
  },
  {
    path: "/authenticated",
    name: "authenticated",
    component: () =>
      import(/* webpackChunkName: "user" */ "./views/auth/Authenticated"),
  },
  {
    path: "/auth",
    component: () => import(/* webpackChunkName: "user" */ "./views/auth"),
    redirect: "/auth/login",
    children: [
      {
        path: "login",
        component: () =>
          import(/* webpackChunkName: "user" */ "./views/auth/Login"),
      },
      {
        path: "register",
        component: () =>
          import(/* webpackChunkName: "user" */ "./views/auth/Register"),
      },
      {
        path: "forgot-password",
        component: () =>
          import(/* webpackChunkName: "user" */ "./views/auth/ForgotPassword"),
      },
      {
        path: "reset-password",
        component: () =>
          import(/* webpackChunkName: "user" */ "./views/auth/ResetPassword"),
      },
    ],
  },
  {
    path: "*",
    component: () => import(/* webpackChunkName: "error" */ "./views/Error"),
  },
];

const router = new VueRouter({
  linkActiveClass: "active",
  routes,
  mode: "history",
});
router.beforeEach(AuthGuard);
export default router;
