import axios from "axios";
import { loadProgressBar } from "./loadProgressBar";
import toast from "./components/Common/Toast";
import { apiUrl, apiKey } from "./constants/config";
import { setCurrentUser, getCurrentUser, getTokens, setTokens } from "./utils";
import Vue from "vue";
import store from "./store";

export const StatusCode = {
  SUCCESS: "10000",
  FAILURE: "10001",
  RETRY: "10002",
  INVALID_ACCESS_TOKEN: "10003",
};
/**
 * Axios basic httpConfiguration
 * Some general httpConfiguration can be added like timeout, headers, params etc. More details can be found on https://github.com/axios/axios
 * */
const httpConfig = {
  baseURL: apiUrl,
  headers: {
    "x-api-key": apiKey,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "application/json",
  },
};

/**
 * Creating the instance of Axios
 * It is because, in large scale application we may need to consume APIs from more than single server,
 * So, may need to create multiple http client with different httpConfig
 * Only this client will be used rather than axios in the application
 **/
const httpClient = axios.create(httpConfig);

/**
 * Auth interceptors
 * @description Configuration related to AUTH token can be done in interceptors.
 * Currenlty it is just doing nothing but idea to to show the capability of axios and its interceptors
 * In future, interceptors can be created into separate files and consumed into multiple http clients
 * @param {*} httpConfig
 */
const authInterceptor = (httpConfig) => {
  /** add auth token */
  const tokens = getTokens();
  if (tokens && tokens.accessToken) {
    httpConfig = {
      ...httpConfig,
      headers: {
        ...httpConfig.headers,
        Authorization: "Bearer " + tokens.accessToken,
      },
    };
  }
  return httpConfig;
};

const loggerInterceptor = (httpConfig) => {
  /** Add logging here */
  return httpConfig;
};

/** Adding the request interceptors */
httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.request.use(loggerInterceptor);
loadProgressBar(
  {
    showSpinner: false,
  },
  httpClient
);
/** Adding the response interceptors */
httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    /** Do something with response error */
    if (
      error.response.data.message === "Token is not valid" ||
      error.response.data.message === "Access token invalid" ||
      error.response.data.message === "Token is expired" ||
      error.response.data.message === "Invalid access token" ||
      error.response.data.statusCode === "10003"
    ) {
      // clear storage and redirect to login page
      store.dispatch("clearUserInfo");
      location.reload();
    }
    if (
      error.httpConfig &&
      error.httpConfig.hasOwnProperty("errorHandle") &&
      error.httpConfig.errorHandle === false
    ) {
      return Promise.reject(error);
    }

    //   if (error.response) {

    //     switch (error.response.data.statusCode) {
    //       default:
    //         toast.error(error.response.data.message);
    //         break;
    //     }
    //   }
    console.log(error);
    return Promise.reject(error);
  }
);

export { httpClient };
