import Vue from 'vue'

import AOS from 'aos';
import 'aos/dist/aos.css';
import Colxx from './components/Common/Colxx'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import vuetify from '@/plugins/vuetify' // path to vuetify export

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import "./assets/css/main.scss";
import "./assets/css/app.scss";

import * as VueAos from 'vue-aos'
import router from './router'
import store from './store'

// Notification Component Add
import Notifications from './components/Common/Notification'
import { httpClient } from "./service";
Vue.prototype.$http = httpClient;

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueAos)
Vue.use(Notifications);

// import NewApp from './NewApp.vue'
import MainApp from './views/app'
Vue.component('b-colxx', Colxx);
// Vue.config.productionTip = false

new Vue({
  vuetify,
  created(){
    AOS.init();
  },
  router,
  store,
  render: h => h(MainApp),
}).$mount('#app')
