// import firebase from 'firebase/app'
import { httpClient } from "../../service";
import { apiUrl, apiKey, apiClassUrl  } from "../../constants/config";
// import 'firebase/auth'

import {
  setCurrentUser,
  getCurrentUser,
  getTokens,
  setTokens,
} from "../../utils";

export default {
  state: {
    classTypes: [],
    classTypeError: null,
    classTypeProcessing: false,
    classTypeSuccess: null,
  },
  getters: {
    classTypes: (state) => state.classTypes,
    classTypeProcessing: (state) => state.classTypeProcessing,
    classTypeError: (state) => state.classTypeError,
    classTypeSuccess: (state) => state.classTypeSuccess,
  },
  mutations: {
    setclassTypes(state, payload) {
      state.classTypes = payload;
      state.classTypeProcessing = false;
      state.classTypeError = null;
    },
    setMyClass(state, payload) {
      state.myClass = payload;
      state.classTypeProcessing = false;
      state.classTypeError = null;
    },
    setclassTypeProcessing(state, payload) {
      state.classTypeProcessing = payload;
      state.classTypeError = null;
    },
    setclassTypeError(state, payload) {
      state.classTypeError = payload;
      state.classTypeProcessing = false;
    },
    setRegisterclassTypeSuccess(state) {
      state.classTypeError = null;
      state.classTypeProcessing = false;
      state.classTypeSuccess = true;
    },
    clearclassTypeError(state) {
      state.classTypeError = null;
    },
  },
  actions: {
    getclassTypes({ commit, state }, search) {
      const tokens = getTokens();
      httpClient
        .get(`${apiClassUrl}/c1/class_type`, {
          params: {
            perPage: state.perPage,
            page: state.page,
            search: search,
            // filter: JSON.stringify([
            //   {
            //     field: "program",
            //     operator: "is",
            //     value: "template",
            //   },
            // ]),
          }
        })
        .then((response) => {
          console.log('----------------------------------------------');
          console.log(response.data.data.list);
          commit("setclassTypes", response.data.data.list);
        });
    },
  },
};
