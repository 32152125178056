// import firebase from 'firebase/app'
import { httpClient } from "../../service";
// import 'firebase/auth'
import { apiUrl, apiKey, apiClassUrl  } from "../../constants/config";
import {
  setCurrentUser,
  getCurrentUser,
  getTokens,
  setTokens,
} from "../../utils";
import * as _ from "lodash";
export default {
  state: {
    classes: [],
    myClass: null,
    classError: null,
    classProcessing: false,
    classSuccess: null,
  },
  getters: {
    classes: (state) => state.classes,
    myClass: (state) => state.myClass,
    classProcessing: (state) => state.classProcessing,
    classError: (state) => state.classError,
    classSuccess: (state) => state.classSuccess,
  },
  mutations: {
    setClasses(state, payload) {
      state.classes = payload;
      state.classProcessing = false;
      state.classError = null;
    },
    setMyClass(state, payload) {
      state.myClass = payload;
      state.classProcessing = false;
      state.classError = null;
    },
    setclassProcessing(state, payload) {
      state.classProcessing = payload;
      state.classError = null;
    },
    setclassError(state, payload) {
      state.classError = payload;
      state.classProcessing = false;
    },
    setRegisterclassSuccess(state) {
      state.classError = null;
      state.classProcessing = false;
      state.classSuccess = true;
    },
    clearclassError(state) {
      state.classError = null;
    },
  },
  actions: {
    getClasses({ commit, state }, payload) {
      const tokens = getTokens();
      httpClient
        .get(`${apiClassUrl}/c1/class`, {
          params: {
          }
        })
        .then((response) => {
          commit("setClasses", response.data.data);
        });
    },
    registerClass({ commit, rootState , dispatch }, payload) {
      const tokens = getTokens();
      let classType = payload.selectedClassType.map((item) => item._id);
      if (payload.subscriptionType === 'all_pass' && rootState.classType?.classTypes) {
        classType = rootState.classType?.classTypes?.map((item) => item._id);
      }
      httpClient
        .post(
          `${apiClassUrl}/c1/enrollment`,
          {
            type: payload.subscriptionType,
            desc: "Mr.bro Summer Class",
            classType: classType,
            class: payload.selectedClass?.id,
            price: payload.price,
            grade: payload.selectVIP ? "VIP" : "NORMAL",
            coupon: _.get(payload, ['coupon', 'code']) || _.get(payload, ['coupon', 'id'])
          }
        )
        .then((response) => {
          dispatch("requestPayment", {
            enrollment: response.data.data._id,
            name: response.data.data.desc,
            amount: response.data.data.price,
          });
        });
    },
  },
};
