<template>
  <div fluid-sm id="container" >
    <div no-gutters class="w-75 m-auto d-lg-flex">
      <b-col class="leftbox">
        <img src="../assets/Images/cancellation_confirmation.svg" alt="" class="w-100" />
      </b-col>
      <b-col
        class="rightbox d-flex justify-content-between "
        align-h="stretch"
      >
        <!-- <b-row no-gutters align-h="end" class="py-2 pr-2">
        </b-row> -->
        <!-- <b-row no-gutters> -->
          <b-col align-h="stretch" class="d-flex align-items-center py-sm-5">
            <div>
              <div class="text-center w-75 m-auto">
              <span class="h2 font-weight-bold d-block">수강취소</span>
              <span class="d-block"
                >수강취소시 기존 수강정보를 보실 수 없습니다.스페셜 클래스 및
                무료 제공 서비스를 더이상 이용하실 수 없습니다.</span
              >
            </div>
            <div v-if="!disablePaymentConfig">
              <input
                v-model="msg"
                type="text"
                placeholder="취소 사유를 입력해 주세요."
                class="input-wrapper w-75 mx-auto p-2 my-3 d-block"
              />
              <b-button
                class="button-wrapper w-75 m-auto d-block"
                :disabled="!msg"
                @click="requestCancelPayment"
                >수강 취소하기</b-button
              >
            </div>
            </div>
          </b-col>
        <!-- </b-row> -->
      </b-col>
    </div>
      <Footer style="margin-top:3em"/>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import {disablePaymentConfig } from "../constants/config";
import Footer from './Footer.vue';
export default {
  components: { Footer },
  name: "CancelConformation",
  data() {
    return {
      msg: "",
      disablePaymentConfig: disablePaymentConfig
    };
  },
  methods: {
    requestCancelPayment() {
      if (this.msg) {
        this.$notify(
          "success",
          "cancel payment Success",
          "cancel payment success",
          {
            duration: 3000,
            permanent: false,
          }
        );
        this.$store.dispatch("requestCancelPayment", { msg: this.msg });
        // this.$emit("close");
        this.$router.push('/')
      }
    },
  },
};
</script>

<style scoped>
#container {
  background:white;
  padding-top: 7em;
}
.leftbox {
  border: 1px solid #eb5757;
  background: #eb5757;
  width: 100%;
  height: auto;
  border-radius: 20px 0px 0px 20px;
  box-shadow: 5px 20px 50px rgba(0, 0, 0, 0.2),
    0px 2px 6px rgba(8, 35, 48, 0.16);
}

.rightbox {
  border: 1px solid white;
  background: white;
  flex-direction: column;
  box-shadow: 5px 20px 50px rgba(0, 0, 0, 0.2),
    0px 2px 6px rgba(8, 35, 48, 0.16);
    border-radius: 0px 20px 20px 0px;
}

.input-wrapper {
  border: 1.5px solid #535353;
  border-radius: 8px;
  width: 60%;
}

.button-wrapper {
  border: 1px solid #eb5757;
  background: #eb5757;
  color: white;
  border-radius: 8px;
}
.cursor_pointer {
  cursor: pointer;
}
@media (max-width: 991.98px) {
.leftbox{
  border-radius:20px 20px 0 0 ;
}
.rightbox{
  border-radius: 0 0 20px 20px;
}
}
</style>