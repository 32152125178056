// import firebase from 'firebase/app'
import { httpClient } from "../../service";
// import 'firebase/auth'
import {
  currentUser,
  isAuthGuardActive,
  apiAuthUrl,
  apiKey,
} from "../../constants/config";
import {
  setCurrentUser,
  getCurrentUser,
  getTokens,
  setTokens,
} from "../../utils";

export default {
  state: {
    currentUser: isAuthGuardActive ? getCurrentUser() : currentUser,
    tokens: getTokens(),
    loginError: null,
    registerError: null,
    processing: false,
    forgotMailSuccess: null,
    resetPasswordSuccess: null,
    registerSuccess: null,
  },
  getters: {
    currentUser: (state) => state.currentUser,
    tokens: (state) => state.tokens,
    processing: (state) => state.processing,
    loginError: (state) => state.loginError,
    forgotMailSuccess: (state) => state.forgotMailSuccess,
    registerSuccess: (state) => state.registerSuccess,
    registerError: (state) => state.registerError,
    resetPasswordSuccess: (state) => state.resetPasswordSuccess,
  },
  mutations: {
    setUser(state, payload) {
      state.currentUser = payload;
      state.processing = false;
      state.loginError = null;
    },
    setTokens(state, payload) {
      state.tokens = payload;
      state.processing = false;
      state.loginError = null;
    },
    setLogout(state) {
      state.currentUser = null;
      state.tokens = null;
      state.processing = false;
      state.loginError = null;
    },
    setProcessing(state, payload) {
      state.processing = payload;
      state.loginError = null;
      state.registerError = null;
      state.registerSuccess = null;
    },
    setError(state, payload) {
      state.loginError = payload;
      state.currentUser = null;
      state.processing = false;
    },
    setRegisterError(state, payload) {
      state.registerError = payload;
      state.currentUser = null;
      state.processing = false;
    },
    setRegisterSuccess(state) {
      state.registerError = null;
      state.currentUser = null;
      state.processing = false;
      state.registerSuccess = true;
    },
    setForgotMailSuccess(state) {
      state.loginError = null;
      state.currentUser = null;
      state.processing = false;
      state.forgotMailSuccess = true;
    },
    setResetPasswordSuccess(state) {
      state.loginError = null;
      state.currentUser = null;
      state.processing = false;
      state.resetPasswordSuccess = true;
    },
    clearError(state) {
      state.loginError = null;
      state.processing = false;
      state.registerSuccess = null;
    },
  },
  actions: {
    login({ commit, dispatch }, payload) {
      commit("clearError");
      commit("setProcessing", true);
      httpClient
        .post(
          `/v1/login/basic`,
          {
            email: payload.email.toLowerCase(),
            password: payload.password,
          }
          // {
          //   headers: {
          //     "x-api-key": apiKey,
          //     "Content-Type": "application/json",
          //   },
          // }
        )
        .then(
          (response) => {
            const userInfo = response.data.data;

            setTokens(userInfo.tokens);
            commit("setTokens", userInfo.tokens);
            console.log(userInfo);
            const user = {
              date: "Last seen today 15:24",
              id: userInfo.user._id,
              img: userInfo.user.profilePicUrl,
              role: 0,
              title: userInfo.user.name,
              uid: userInfo.user._id,
              ...userInfo.user,
            };
            setCurrentUser(user);
            commit("setUser", user);
            console.log("login success");
            dispatch("getCurrentEnrollment");
          },
          (err) => {
            setCurrentUser(null);
            setTokens(null);
            commit("setError", err.response.data.message);
            setTimeout(() => {
              commit("clearError");
            }, 3000);
          }
        );
    },
    loginPassport({ commit, dispatch }, payload) {
      commit("clearError");
      commit("setProcessing", true);
      if (payload.user && payload.tokens) {
        setTokens(payload.tokens);
        commit("setTokens", payload.tokens);
        const user = {
          date: "Last seen today 15:24",
          id: payload.user._id,
          img: payload.user.profilePicUrl,
          role: 0,
          title: payload.user.name,
          uid: payload.user._id,
          ...payload.user,
        };
        setCurrentUser(user);
        commit("setUser", user);
        dispatch("getCurrentEnrollment");
      } else {
        setCurrentUser(null);
        setTokens(null);
        commit("setError", err.response.data.message);
        setTimeout(() => {
          commit("clearError");
        }, 3000);
      }
    },
    register({ commit }, payload) {
      commit("clearError");
      commit("setProcessing", true);
      httpClient
        .post(`/v1/signup/basic`, {
          name: payload.name,
          email: payload.email.toLowerCase(),
          password: payload.password,
        })
        .then(
          (response) => {
            setCurrentUser({
              email: response.data.email,
            });
            console.log("clear error register");
            commit("clearError");
            commit("setRegisterSuccess");
          },
          (err) => {
            console.log("1111111111", err.response.data.message);
            setCurrentUser(null);
            setTokens(null);
            commit("setRegisterError", err.response.data.message);
            setTimeout(() => {
              commit("clearError");
            }, 3000);
          }
        );
    },
    forgotPassword({ commit }, payload) {
      commit("clearError");
      commit("setProcessing", true);
      httpClient
        .post(`${apiAuthUrl}/v1/fp/send-mail`, {
          email: payload.email.toLowerCase(),
        })
        .then(
          (response) => {
            commit("clearError");
            commit("setForgotMailSuccess");
          },
          (err) => {
            commit("setError", err.response.data.message);
            setTimeout(() => {
              commit("clearError");
            }, 3000);
          }
        );
    },
    resetPassword({ commit }, payload) {
      commit("clearError");
      commit("setProcessing", true);
    },
    resendCode({ commit, dispatch }, payload) {
      return httpClient.post(`/v1/resend-code`, {
        email: payload.email.toLowerCase(),
      }).then();
    },

    signOut({ commit }) {
      httpClient.delete("/v1/logout").then(
        (response) => {
          setCurrentUser(null);
          setTokens(null);
          commit("setLogout");
        },
        (err) => {
          setCurrentUser(null);
          setTokens(null);
          commit("setLogout");
        }
      );
    },
    clearUserInfo({ commit }) {
      setCurrentUser(null);
      setTokens(null);
      commit("setLogout");
    },
  },
};
