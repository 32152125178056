import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import payment from './modules/payment'
import classes from './modules/classes'
import classType from './modules/classType'
import enrollment from './modules/enrollment'
import coupon from './modules/coupon';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    enrollment: null,
    page: 1,
    perPage: 10,
    totalCount: 0,
    hashPath: '',
    path: '/',
    screen: window.innerWidth,
    videos: {
      sarah: "https://www.youtube.com/embed/HlS4e0y9M-k",
      prince: "https://www.youtube.com/embed/CLJNmwPGnbE",
      jackie: "https://www.youtube.com/embed/bDxYJE9Jw1o",
      david: "https://www.youtube.com/embed/-WK_JMxCjaI",
      miso: "https://www.youtube.com/embed/_xMTNuW4OaU",
      bo: "https://www.youtube.com/embed/osJy9kXlm5Q",
      lara:"https://www.youtube.com/embed/FLhZr9HSzw8",
      nicole: "https://www.youtube.com/embed/60JT-4MDdtQ",
      rasmouth: "https://www.youtube.com/embed/6NXkvD2lBTM",
      erin: "https://www.youtube.com/embed/ofucKE6M54k",
      maddie: "https://www.youtube.com/embed/UdqxFXCNF1I",
      alex: "https://www.youtube.com/embed/PN-6R_uJVRg",
      joran: "https://www.youtube.com/embed/VK727AhVoIY",
      musosem: "https://www.youtube.com/embed/_xMTNuW4OaU"
    }
  },
  getters: {
    videos: (state) => state.videos,
    path: (state) => state.path,
    hashPath: (state) => state.hashPath,
    path: (state) => state.path
  },
  mutations: {
    setScreen(state, payload) {
      state.screen = payload;
    },
    setPath(state, payload) {
      state.path = payload;
    },
    setHashPath(state, payload) {
      console.log(payload);
      state.hashPath = payload;
    }
  },
  modules: {
    user,
    payment,
    classes,
    classType,
    enrollment,
    coupon
  }
})
