// import firebase from 'firebase/app'
import { httpClient } from "../../service";
import { apiUrl, apiKey, apiClassUrl  } from "../../constants/config";
import { checkPlatform } from "../../utils";
// import 'firebase/auth'

import {
  setCurrentUser,
  getCurrentUser,
  getTokens,
  setTokens,
} from "../../utils";

export default {
  state: {
    enrollmentData: null,
    enrollmentError: null,
    enrollmentProcessing: false,
    enrollmentSuccess: null,
  },
  getters: {
    enrollmentData: (state) => state.enrollmentData,
    enrollmentProcessing: (state) => state.enrollmentProcessing,
    enrollmentError: (state) => state.enrollmentError,
    enrollmentSuccess: (state) => state.enrollmentSuccess,
  },
  mutations: {
    setEnrollmentData(state, payload) {
      state.enrollmentData = payload;
      state.enrollmentProcessing = false;
      state.enrollmentError = null;
    },
    // setMyClass(state, payload) {
    //   state.myClass = payload;
    //   state.enrollmentProcessing = false;
    //   state.enrollmentError = null;
    // },
    // setEnrollmentProcessing(state, payload) {
    //   state.enrollmentProcessing = payload;
    //   state.enrollmentError = null;
    // },
    // setEnrollmentError(state, payload) {
    //   state.enrollmentError = payload;
    //   state.enrollmentProcessing = false;
    // },
    // setRegisterenrollmentSuccess(state) {
    //   state.enrollmentError = null;
    //   state.enrollmentProcessing = false;
    //   state.enrollmentSuccess = true;
    // },
    // clearenrollmentError(state) {
    //   state.enrollmentError = null;
    // },
  },
  actions: {
    getCurrentEnrollment({ commit, state, dispatch }, payload) {
      const tokens = getTokens();
      if (tokens && tokens.accessToken) {
        httpClient
        .get(`${apiClassUrl}/c1/current_enrollment`)
        .then((response) => {
          const enrollmentData = response.data.data;
          console.log(enrollmentData);
          commit("setEnrollmentData", enrollmentData);
        });
      } else {
        commit("setEnrollmentData", null);
      }
      
    },
  },
};
