<template>
  <div>
    <div>
      <b-navbar
        :class="{ change_color: scrollPosition > 50 }"
        variant="transperant"
        class="w-100 m-auto navbar fixed-top"
      >
        <div class="container-fluid w-75 m-auto">
          <b-navbar-brand href="/">
            <img
              src="../assets/newimage/new-icon.svg"
              width="100px"
              
              alt="mr.bro logo"
              class=""
            />
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="nav-name mt-4 ml-4">
              <b-navbar-brand
                class="ml-1"
                v-for="link in links"
                :key="link.name"
                @click="goTo(link.path)"
                >{{ link.name }}</b-navbar-brand
              >
              <!-- <b-nav-item class="ml-2" v-for="link in links" :key="link.name" href="#">{{link.name}}</b-nav-item> -->
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto mt-4">
              <label v-if="tokens && tokens.accessToken">
                <div class="d-flex position-relative user-profile">
                  <div class="pr-3">
                    <span
                      class="d-block"
                      :class="{ 'text-white': !(scrollPosition > 50) }"
                      >{{ userName }}</span
                    >
                    <b-badge pill class="d-inline-block bg-danger" v-if="classTypeString">{{
                      classTypeString
                    }} </b-badge>
                    <b-badge pill class="d-inline-block bg-info" v-else>
                    수강신청 정보없음
                    </b-badge>
                    <span
                      class="d-inline-block color-pink font-weight-bold info" style="padding-left:6px !important;"
                      >  {{ grade }}</span
                    >
                  </div>
                  <div class="position-relative profile">
                    <img
                      width="100%"
                      height="100%"
                      :alt="userName"
                      :src="profilePicUrl"
                    />
                  </div>
                  <input type="checkbox" id="dropdown_toggle" class="d-none" />
                  <div class="position-absolute dropdown-items-container d-none">
                    <div
                    class="p-2 mx-2 bg-white profile-sign position-relative"
                  >
                    <!-- <b-button class="dropdown_button my-1 p-1" disabled
                      >수업정보가 없습니다.</b-button
                    > -->
                    <b-button
                      class="dropdown_button my-1 p-1"
                      @click="$router.push('/class_details')"
                    >
                      <div class="w-75 m-auto">
                        <img
                          src="../assets/Images/logout.svg"
                          width="16px"
                          height="16px"
                          alt="logout"
                        />
                        <span class="pl-3">등록정보</span>
                      </div>
                    </b-button>
                    <b-button class="dropdown_button my-1 p-1">
                      <div class="w-75 m-auto" @click="logout">
                        <img
                          src="../assets/Images/logout.svg"
                          width="16px"
                          height="16px"
                          alt="logout"
                        />
                        <span class="pl-3">Logout</span>
                      </div>
                    </b-button>
                  </div>
                  </div>
                </div>
              </label>
              <b-button v-else size="lg" class="sign-up" @click="goToLogin"
                >Sign Up Now
              </b-button>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>
    <b-modal
      ref="cancellation-modal"
      hide-footer
      hide-header
      size="xl"
      centered
    >
      <cancel-confirmation
        @close="
          $refs['cancellation-modal'].hide();
          $refs['all-pass-modal'].show();
        "
      ></cancel-confirmation>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import AllPass from "./AllPass";
import CancelConfirmation from "./CancelConformation";
export default {
  name: "NewNavbar",
  components: {
    "all-pass": AllPass,
    "cancel-confirmation": CancelConfirmation,
  },
  data() {
    return {
      scrollPosition: null,
      links: [
        { id: 1, name: "Home", path: "home" },
        { id: 2, name: "English Class", path: "english-classes" },
        { id: 3, name: "Event Class", path: "event-classes" },
        { id: 4, name: "Teachers", path: "teachers" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      tokens: "tokens",
      classTypes: "classTypes",
      enrollmentData: "enrollmentData",
    }),
    grade: function () {
      return this.enrollmentData && this.enrollmentData.grade === "VIP"
        ? "VIP"
        : "";
    },
    userName: function () {
      return this.currentUser ? this.currentUser.name : "";
    },
    profilePicUrl: function () {
      return this.currentUser && this.currentUser.profilePicUrl
        ? this.currentUser.profilePicUrl
        : "https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png";
    },
    classTypeString: function () {
      if (this.enrollmentData && this.enrollmentData.classType) {
        const classTypeArr = this.enrollmentData.classType.map(
          (item) => item.name
        );
        if (classTypeArr && classTypeArr.length == 4) {
           return " ALL PASS ";
        } else  if (classTypeArr && classTypeArr.length > 0) {
          return classTypeArr.join(" / ");
        } else {
          return "";
        }
      }
    },
  },
  methods: {
    ...mapActions(["signOut"]),
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    goToLogin() {
      console.log("accessToken");
      console.log(this.tokens?.accessToken);
      console.log(this.currentUser);
      this.$router.push("/auth/login");
    },
    logout() {
      console.log("LOG-OUT");
      this.signOut().then(() => {
        this.$router.push("/auth/login");
      });
    },
     goTo(p) {
      this.$store.commit('setHashPath', p);
      this.$router.push('/').catch(err => {
        return;
      }) 
    }
  },

  mounted() {
    this.$store.dispatch("getCurrentEnrollment");
    window.addEventListener("scroll", this.updateScroll);
    console.log(this.tokens);
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;900&display=swap");

.nav-name {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: rgba(50, 50, 50);
  cursor: pointer;
}

.navbar-brand {
  font-size: 0.9rem !important;
}

.mt-4,
.mx-4 {
  margin-top: unset !important;
}

.sign-up {
  background-color: rgba(255, 42, 88, 1) !important;
  border-color: rgba(255, 42, 88, 1) !important ;
  font-size: 1rem;
}

.change_color {
  background-color: white;
  box-shadow: 0px 0px 10px rgb(200, 200, 200);
}
.user-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.profile {
  border-radius: 25px;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border: 2px solid #fd6270;
}
.color-pink {
  color: #ff2a58;
}
.info {
  font-size: 14px;
}
.cursor-pointer {
  cursor: pointer;
}
.dropdown-items-container {
  top: 80%;
  width: 100%;
  left: 0;
  padding-top: 1em;
  z-index: 2000;
  margin-top: 10px;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}
.profile-sign::before {
  position: absolute;
  content: "";
  background: white;
  width: 10px;
  height: 10px;
  top: -5px;
  right: 10px;
  transform: rotate(45deg);
  border-top: 2px solid darkgrey ;
  border-left: 2px solid darkgrey;
}
#dropdown_toggle:hover+ .dropdown-items-container {
  display: flex !important;
}

.dropdown_button {
  border-radius: 25px;
  width: 100%;
  border: none;
  background: #FFD602;
}
.dropdown_button:hover {
  background: #ff6e91;
}
.profile-sign{
  border: 2px solid darkgrey;
  border-radius: 5px;
}
</style>