<template>
  <div>
    <PageLayout>
      <div class="w-100 mx-auto">
        <div class="py-5 text-center mt-n2">
          <img
            src="../assets/Images/thanks_and_congratulations.svg"
            class="w-30 h-auto"
            alt="thanks_and_congratutlations"
          />
        </div>
        <div class="mx-lg-5 mx-md-3 content pt-5">
          <span class="d-block text-center font-weight-bold"
            >{{userName}} 님의 등록 완료된 수업정보입니다.</span
          >
          <b-row no-gutters class="px-5 mt-5">
            <b-col lg="4" xl="4" md="12" sm="12" xs="12">
              <label
              v-for="(option, index) in options"
              :key="index"
                class="w-75 mx-auto my-4 d-block position-relative cursor_pointer"
              >
                <div
                  :class="[
                    'w-100 px-2 py-2 custom-check-box d-flex justifi-content-start align-items-center',
                    {
                      'selected': selected,
                      'unselected': !selected,
                    },
                  ]"
                >
                  <b-form-checkbox
                    class="d-none pr-1"
                  ></b-form-checkbox>
                  <img
                    src="../assets/Images/checked.svg"
                    v-if="selected"
                    alt="checked"
                  />
                  <img
                    src="../assets/Images/unchecked.svg"
                    v-if="!selected"
                    alt="checked"
                  />
                  <span class="option pl-2 text-white">{{ option }}</span>
                  <span
                    :class="[
                      'position-absolute option-number font-weight-bold',
                      {
                        black: selected,
                        gray: !selected,
                      },
                    ]"
                    >{{ index + 1 }}</span
                  >
                </div>
              </label>
            </b-col>
            <b-col lg="4" xl="4" md="12" sm="12" xs="12" class="d-flex align-items-center" align-h="stretch">
                <div class="w-100">
                    <div class="d-flex my-1 justify-content-around align-items-center">
                    <img src="../assets/Images/all_pass_prices.svg" width="40px" height="40px" alt="all_pass_prices">
                    <span v-if="isVIP" class="color-pink"><b>VIP</b> 수강 신청</span>
                </div>
              <b-button
              :disabled="true"
              class="select_class w-100 d-flex justify-content-between p-0 align-items-center"
            >
              <img
                src="../assets/Images/calendar-silhouette.svg"
                width="20px"
                height="20px"
                alt="calendar-silhouette"
                class="ml-5"
              />
              <div class="mr-5" v-if="isVIP">
                <span class="d-block">{{ classInfo.teacherName }}</span>
                <span class="d-block">{{ classInfo.classTime }}</span>
              </div>
            </b-button>
                </div>
            </b-col>
            <b-col class="mt-5">
              <div class="bg-white w-75 m-auto border-radius px-3">
                  <span class="info"> 수업기간 : {{classInfo.programDesc}}</span>
              </div>
              <div class="bg-white w-75 mx-auto border-radius my-3 p-3">
                  <span class="d-block font-weight-bold py-3">결제금액</span>
                  <span class="d-block font-weight-bold color-pink h3">￦ {{price}}</span>
              </div>
            </b-col>
          </b-row>
          <b-row no-gutters class="pb-1">
            <b-col lg="4" xl="4" md="12" sm="12" xs="12" align-h="stretch" class="d-flex align-items-end">
                <span class="color-pink info">* 추가적인 수업 안내는 이메일과 Instagram을 통해 공지됩니다.</span>
            </b-col>
            <b-col lg="4" xl="4" md="12" sm="12" xs="12" class="text-center">
                <img src="../assets/Images/congratulations.svg" width="150px" height="auto" alt="congratulations">
            </b-col>
            <b-col lg="4" xl="4" md="12" sm="12" xs="12" align-h="stretch" class="mt-3 mb-3 d-flex align-items-end justify-content-end">
            <b-button   @click="$emit('close')"
            class="custom-button m-md-auto py-2 px-3 d-flex justify-content-around align-items-center" >
            <span class="d-block info" @click="$router.push('/')">홈으로 돌아가기</span>
            <b-icon icon="arrow-right"></b-icon>
          </b-button>
            </b-col>
          </b-row>
        </div>
    </div>
    </PageLayout>
  </div>
</template>

<script>
import PageLayout from '../layouts/pageLayout'
import { mapGetters, mapMutations, mapActions } from "vuex";
import * as _ from "lodash";
export default {
  name: "AllPass",
  components: {
    PageLayout
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      tokens: "tokens",
      classTypes: "classTypes",
      enrollmentData: "enrollmentData",
    }),
    grade: function () {
      console.log(this.enrollmentData)
      return this.enrollmentData && this.enrollmentData.grade === "VIP"
        ? "VIP"
        : "";
    },
    isVIP: function () {
      return this.enrollmentData && this.enrollmentData.grade === "VIP"
        ? true
        : false;
    },
    price: function () {
      return this.enrollmentData && this.enrollmentData.price
        ? this.enrollmentData.price
        : 0;
    },
    userName: function () {
      return this.currentUser ? this.currentUser.name : "";
    },
    profilePicUrl: function () {
      return this.currentUser && this.currentUser.profilePicUrl
        ? this.currentUser.profilePicUrl
        : "https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png";
    },
    options: function() {
      if (this.enrollmentData && this.enrollmentData.classType) {
        const classTypeArr = this.enrollmentData.classType.map(
          (item) => item.name
        );
        if (classTypeArr && classTypeArr.length > 0) {
          return classTypeArr;
        } else {
          return [];
        }
      }
    },
    classInfo: function () {
      return {
        classTime: _.get(this.enrollmentData, ['class', '0' ,'classTime']),
        programDesc: _.get(this.enrollmentData, ['classType', '0' ,'program', 'desc']),
        teacherName: _.get(this.enrollmentData, ['class', '0' ,'teacher', 'name'])
      }
    },
    classTypeString: function () {
      if (this.enrollmentData && this.enrollmentData.classType) {
        const classTypeArr = this.enrollmentData.classType.map(
          (item) => item.name
        );
        if (classTypeArr && classTypeArr.length > 0) {
          return classTypeArr.join(" / ");
        } else {
          return "";
        }
      }
    },
  },
  data() {
      return {
          selected: true,
      //     options: [
      //   "일상회화 수업",
      //   "초급회화 수업",
      //   "중고급 수업",
      //   "비즈니스 수업",
      // ],
      }
  },
  methods: {
    openCancelPayment(e) {
      this.$emit('close');
      this.$emit('confirm');
    }
  }
};
</script>

<style scoped>
.content {
  background: #fff5ad;
  border-radius: 10px;
}
.custom-check-box {
  border-radius: 25px;
}

.cursor_pointer {
  cursor: pointer;
}
.option-number {
    padding-left: 0.8em;
    top: -1em;
    right: 2em;
    transform: scale(3);
}
.unselected {
  background: #d8d8d8;
}

.selected {
  background: #ffe63a;
}
.select_class {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 60px;
  background: white;
  border: none;
  color: black;
  min-height: 60px;
}
.color-pink {
  color: #ff2a58;
}

.custom-button {
  background: #ff2a58;
  border-radius: 25px;
  border: none;
}
.border-radius {
    border-radius: 8px;
}

.coursecancel-wrapper{
    border: 1px solid #232222;
    background: #232222;
border-radius: 25px;
color: #615E5E;
}

.coursecancel-wrapper:hover{
    border: 1px solid #FF9A9A;
    background: #FF9A9A;
    color: #FF2A58;
}
.info {
    font-size: 10px;
  }

@media (max-width: 600px){
  .modal-body {
    padding: 0px;
  }
  .option {
  font-size: 10px;
}
} 
 @media (min-width: 601px){
  .modal-body {
    padding: 16px;
  }
  .info {
    font-size: 14px;
  }
  .option {
  font-size: 14px;
}
}

.close-icon {
  position:absolute;
  font-size:14px;
  top: 0.5em;
  right: 0.5em;
}
.cancel{
  font-size: 14px;
}
.arrow{
  width: 80px;
}
.cancel-btn{
  border-radius: 3%;
}
.register{
  background: rgba(196, 196, 196, 0.9);
  min-height: 300px;
  display: grid;
  place-items: center;
}
.register-1{
  background-color:  #FCE022;;
}
</style>