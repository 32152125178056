// import firebase from 'firebase/app'
import { httpClient } from "../../service";
import { apiClassUrl } from "../../constants/config";
import * as _ from "lodash";
import moment from "moment";
export default {
  state: {
    allCoupons: [],
    selectedCoupon: null,
    couponError: null,
    couponProcessing: false,
    couponSuccess: null,
  },
  getters: {
    allCoupons: (state) => state.allCoupons,
    selectedCoupon: (state) => state.selectedCoupon,
    couponProcessing: (state) => state.couponProcessing,
    couponError: (state) => state.couponError,
    couponSuccess: (state) => state.couponSuccess,
  },
  mutations: {
    setCouponList(state, payload) {
      state.allCoupons = payload;
      state.couponProcessing = false;
      state.couponError = null;
    },
    setSelectedCoupon(state, payload) {
      state.selectedCoupon = payload;
    },
    addCoupon(state, payload) {
      state.allCoupons = payload;
      state.couponProcessing = false;
      state.couponError = null;
    },
    setCouponProcessing(state, payload) {
      state.couponProcessing = payload;
      state.couponError = null;
    },
    setCouponError(state, payload) {
      state.couponError = payload;
      state.couponProcessing = false;
    },
    setRegistercouponSuccess(state) {
      state.couponError = null;
      state.couponProcessing = false;
      state.couponSuccess = true;
    },
    clearCouponError(state) {
      state.couponError = null;
    },
  },
  actions: {
    getAllCoupons({ commit, state, dispatch }, payload) {
      httpClient
        .get(`${apiClassUrl}/c1/coupon`, {
          params: {
            perPage: state.perPage,
            page: state.page,
          },
        })
        .then((response) => {
          let allCoupons = [];
          if (
            response.data &&
            response.data.data &&
            response.data.data.length > 0
          ) {
            allCoupons = response.data.data.map((coupon) => {
              const expiredDate = moment(coupon.expiredAt).format("YYYY.MM.DD");
              let applicable = true;
              let expired = false;
              if (moment(coupon.expiredAt).isBefore(moment())) {
                applicable = false;
                expired = true;
              }
              return {
                id: coupon._id,
                type: coupon.name,
                code: coupon.hash,
                applicable: applicable,
                discount: coupon.price,
                expired: expired,
                expiryDate: expiredDate,
              };
            });
          }
          commit("setCouponList", allCoupons);
        });
    },
  },
};
