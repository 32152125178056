<template>
  <div>
    <b-card class="card-section w-100">
      <b-row class="pointer py-4" no-gutters align-h="between">
        <b-col sm="12" md="6" xl="6" lg="6" class="text-center">
          <img
            src="@/assets/Images/Mrbroenglish.svg"
            alt=""
            class="img position-relative"
          />
          <p class="copyrights position-relative mt-1">
            © 2021 AprilGoodDay Corp. All rights reserved.
          </p>
          <div>
            <button
              class="terms mx-1"
              @click="$refs['terms_of_use_modal'].show()"
            >
              서비스 이용약관
            </button>
            <button
              class="privacy mx-1"
              @click="$refs['privacy_policy_modal'].show()"
            >
              개인정보의 수집 및 이용에 대한 동의
            </button>
          </div>
        </b-col>

        <b-col sm="12" md="6" xl="6" lg="6" class="text-center mt-xs-3">
          <span class="headings">Social</span>
          <div class="mt-3 icons">
            <b-icon icon="facebook"></b-icon>
            <b-icon icon="twitter" class="px-3"></b-icon>
            <b-icon icon="youtube" class="px-3"></b-icon>
            <b-icon icon="instagram" class="px-3"></b-icon>
            <b-icon icon="linkedin" class="px-3"></b-icon>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <p class="adress text-center w-25 m-auto">
           <span class="d-block"> 주식회사 에이프릴굿데이 | 조현민 서울시 강서구 마곡중앙로 161-8
            893-86-01967</span> <span class="d-block">help@aprilgoodday.com Tel: 070-8625-8624
            2021-서울강서-1860</span>
          </p>
        </b-col>
      </b-row>
    </b-card>
    <div class="footer-in text-center">
      <span class="powered">powered by</span>
      <span class="april-good-day px-2">GoodDayClass</span>
    </div>
    <b-modal
      size="lg"
      scrollable
      hide-footer
      hide-header
      body-class="bg-white"
      ref="terms_of_use_modal"
    >
      <terms-of-use></terms-of-use>
    </b-modal>
    <b-modal
      size="lg"
      scrollable
      hide-footer
      hide-header
      body-class="bg-white"
      ref="privacy_policy_modal"
    >
      <privacy-policy></privacy-policy>
    </b-modal>
  </div>
</template>

<script>
import TermsOfUse from "./TermsOfUse";
import PrivacyPolicy from "./PrivacyPolicy";
export default {
  name: "Footer",
  components: {
    "terms-of-use": TermsOfUse,
    "privacy-policy": PrivacyPolicy,
  },
};
</script>

<style scoped>
.card {
  border-radius: 30px 30px 0 0 !important;
  border: 0 !important;
}
.card-section {
  background-color: gold;
}
.footer-in {
  background: #000;
}
.powered {
  color: white;
}
.april-good-day {
  color: gold;
}
.img {
  width: 175px;
  right: 74px;
}
.headings {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}
.copyrights {
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 0px;
  color: #535353;
}

.icons {
  cursor: pointer;
  color: #535353;
}
.adress {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 13px;
}
.terms {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  color: #040404;
  border: none;
}
.privacy {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: #000000;
  border: none;
}
</style>